import React from "react";
import { Link } from "gatsby";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        <section className="section">
          <h2 className="heading">En Son Yazılanlar</h2>
          <div className="headingLine" />
          {postList.map((post) => (
            <div className="contentBoxWrapper">
              <div className="contentBoxImage">
                <img src={post.cover} alt={post.title} />
              </div>
              <Link to={post.path} key={post.title}>
                <div className="contentBoxContent">
                  <h3>{post.title}</h3>
                  <p className='content-excerpt'>{post.excerpt}</p>
                  {/* <div className="blogTagSection">
                    <span> {post.tags}</span>
                  </div> */}
                  <div
                    className="blogContentSection"
                    style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                  >
                    <time>{post.date}</time>
                  </div>
                  <Link to={post.path} title={post.title}>
                    <button className="buttonBlack">Devam</button>
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </section>
      </div>
    );
  }
}

export default PostListing;
